import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@cfacorp/cowponents';
import styled from 'styled-components/macro';
import { contains } from 'ramda';
import { FeatureFlagService } from '@cfacorp/web-pos-services';
// eslint-disable-next-line import/no-cycle
import {
  DashboardActionButton,
  AreYouSure,
  Icon,
  OrderExportExcel,
} from '../index';
import constants from '../../constants';

const showEditOrderButton = () => FeatureFlagService.variation('edit-order', false);
const showResendEmailButton = () => FeatureFlagService.variation('resend-payment-email', false);

export const DashboardActionsComponent = props => {
  const {
    cancelOrder,
    orderDetails,
    resendPaymentEmail,
    initiateEditOrder,
    guestDetails,
    dashboardActionLoading,
    tooLateToCancelOrEdit,
    paymentEmailResent,
    isPOSOrder,
    isErrorStatus,
    restaurantContactDetails,
  } = props;

  const {
    id, cfaId, email, status,
  } = orderDetails;

  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmToShow, setConfirmToShow] = useState({});

  const cancelled = contains(status, constants.CANCELLED);
  const showResendEmail = status === constants.PAYMENT_PENDING;
  const disable = tooLateToCancelOrEdit || isPOSOrder || isErrorStatus;

  const handleCancelOrder = () => {
    cancelOrder(id, cfaId);
    setShowConfirm(false);
  };

  const handleResendPaymentEmail = () => {
    resendPaymentEmail(id, cfaId);
    setShowConfirm(false);
  };

  const handleEditOrder = () => {
    initiateEditOrder(orderDetails, guestDetails);
    setShowConfirm(false);
  };

  const declineConfirm = () => {
    setShowConfirm(false);
    setConfirmToShow({});
  };

  const toggleConfirmMessage = (type) => {
    const confirmTypes = {
      cancelConfirm: {
        message: constants.GET_DASHBOARD_CANCEL_MESSAGES.message,
        confirm: handleCancelOrder,
        confirmText: constants.GET_DASHBOARD_CANCEL_MESSAGES.confirmText,
        decline: declineConfirm,
        declineText: constants.GET_DASHBOARD_CANCEL_MESSAGES.declineText,
      },
      resendEmailConfirm: {
        message: `${constants.GET_DASHBOARD_RESEND_MESSAGES.message} ${email}?`,
        confirm: handleResendPaymentEmail,
        confirmText: constants.GET_DASHBOARD_RESEND_MESSAGES.confirmText,
        decline: declineConfirm,
        declineText: constants.GET_DASHBOARD_RESEND_MESSAGES.declineText,
      },
    };
    setShowConfirm(!showConfirm);
    setConfirmToShow(confirmTypes[type]);
  };
  const {
    message,
    confirm,
    confirmText,
    decline,
    declineText,
  } = confirmToShow;

  if (cancelled) {
    return (
      <DashboardActions>
        <div data-testid="action-message" className="action-message">
          <Icon>error</Icon>
          {constants.GET_DASHBOARD_CANCEL_MESSAGES.cancelled}
        </div>
      </DashboardActions>
    );
  } if (paymentEmailResent) {
    return (
      <DashboardActions>
        <div data-testid="action-message" className="action-message email">
          <Icon>success</Icon>
          {`${constants.GET_DASHBOARD_RESEND_MESSAGES.emailSent} ${email}`}
        </div>
      </DashboardActions>
    );
  } if (dashboardActionLoading) {
    return (
      <DashboardActions>
        <Spinner data-testid="spinner" />
      </DashboardActions>
    );
  }
  return (
    <DashboardActions>
      {showConfirm
        ? (
          <AreYouSure
            message={message}
            confirm={confirm}
            confirmText={confirmText}
            decline={decline}
            declineText={declineText}
            data-testid="are-you-sure"
          />
        )
        : (
          <>
            {showEditOrderButton() && (
              <DashboardActionButton
                className="edit-order"
                icon="notepad"
                buttonText="Edit Order"
                onClick={handleEditOrder}
                disabled={disable}
              />
            )}
            { showResendEmailButton() && showResendEmail && (
              <DashboardActionButton
                className="payment-email"
                data-cy="resend-button"
                icon="email"
                buttonText="Resend Payment Email"
                onClick={() => toggleConfirmMessage('resendEmailConfirm')}
                disabled={disable}
              />
            ) }
            <DashboardActionButton
              className="cancel-order"
              icon="delete"
              buttonText="Cancel Order"
              onClick={() => toggleConfirmMessage('cancelConfirm')}
              disabled={disable}
            />
            <OrderExportExcel
              orderDetails={orderDetails}
              restaurantContactDetails={restaurantContactDetails}
            />
          </>
        ) }
    </DashboardActions>
  );
};

const DashboardActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  background-color: ${(props) => props.theme.background};
  
  & .action-message {
    height: 98px;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }
  
  & .action-message svg {
    margin: 0 5px 0 0;
    width: 30px;
    height: 30px;
    max-width: 21px;
  }
  
  & .loading {
    width: 134px;
    height: 98px;
    align-self: center;
  }
  
  & .loading img {
    margin: 28px;
  }
  
  @media(max-width: 600px) {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
`;

DashboardActionsComponent.propTypes = {
  tooLateToCancelOrEdit: PropTypes.bool,
  dashboardActionLoading: PropTypes.bool,
  cancelOrder: PropTypes.func,
  orderDetails: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.func,
  ])),
  resendPaymentEmail: PropTypes.func.isRequired,
  paymentEmailResent: PropTypes.bool,
  isPOSOrder: PropTypes.bool,
  isErrorStatus: PropTypes.bool,
  initiateEditOrder: PropTypes.func,
  guestDetails: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.func,
  ])),
  restaurantContactDetails: PropTypes.objectOf(PropTypes.string),
};

DashboardActionsComponent.defaultProps = {
  tooLateToCancelOrEdit: false,
  dashboardActionLoading: false,
  cancelOrder: () => {},
  orderDetails: {},
  guestDetails: {},
  paymentEmailResent: false,
  isPOSOrder: false,
  isErrorStatus: false,
  initiateEditOrder: () => {},
  restaurantContactDetails: {},
};

export default memo(DashboardActionsComponent);
