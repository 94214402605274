/* eslint-disable react/forbid-prop-types */
import 'react-dates/initialize';
import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './react_dates_overrides.css';
import { generateDateSlots } from '../../util/format';

export const DatePicker = props => {
  const {
    input,
    availableDates,
    loading,
    bypassBusinessRules,
  } = props;

  const [isFocused, setIsFocused] = useState(false);

  const { onChange } = input;
  const value = typeof input.value === 'string' ? null : input.value;

  return (
    <SingleDatePicker
      noBorder
      numberOfMonths={1}
      hideKeyboardShortcutsPanel
      disabled={loading}
      placeholder={loading ? 'Retrieving Available Dates...' : 'Select Date'}
      readOnly
      date={value}
      onDateChange={(date) => onChange(date)}
      isDayBlocked={(date) => generateDateSlots(date, availableDates, bypassBusinessRules)}
      focused={isFocused}
      onFocusChange={({ focused }) => setIsFocused(focused)}
    />
  );
};

DatePicker.propTypes = {
  input: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ])).isRequired,
  availableDates: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  bypassBusinessRules: PropTypes.bool,
};

DatePicker.defaultProps = {
  loading: true,
  bypassBusinessRules: false,
};

export default memo(DatePicker);
