/* eslint-disable react/forbid-prop-types */
import { useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Section, SectionHeader } from '@cfacorp/cowponents';
import { getFormValues } from 'redux-form';
import { useReactToPrint } from 'react-to-print';
import {
  ConnectedPaymentOptions,
  SubmitOrderButton,
} from '../components';
import {
  actions as guestActions,
  selectVaultedCards,
  selectCardsLoading,
  selectZipLoading,
  selectCardSelected,
} from '../reducers/guest';
import {
  actions as orderActions, selectOrderIsLoading, selectDestination,
  selectSubmitOrderLoading, selectDeliveryAddressValid, selectUpdateOrderError,
  selectFormattedSubTotalAmount, selectEditMode, selectHideNewPaymentOption, selectDeliveryAddressForOrder, selectSubTotalAmount, selectTotalAmount, selectDeliveryTip, selectDeliveryTipLoading, selectSelectedCustomTipValue, selectSelectedPercentageTipValue, selectPaperGoodsRequired, selectShowDefaultPaperGoodsMessage, selectFormattedTaxAmount,
} from '../reducers/order';
import {
  selectTaxAndTotal,
  selectEventSummaryValid,
  selectSubmitButtonIsDisabled,
  selectCartWithPrices,
  selectAllSubmitWarnings,
  selectPayLaterNotAllowed,
  selectDeliveryTipIsDisplayed,
  selectAllItemsPromoFree,
  selectSpecialInstructionMessage,
} from '../reducers';
import { selectLocationContactDetails, selectLocationNumber } from '../reducers/user';
import {
  selectCateringOccasion,
  selectFormSyncErrors,
} from '../reducers/form';
import DeliveryTips from '../components/DeliveryTips/DeliveryTips';
import { formatPrice } from '../util/format';
import CreateInvoiceButton from '../components/CreateInvoice/CreateInvoiceButton';
import OrderInformation from '../components/OrderInformation/OrderInformation';
import CreateInvoicePage from '../components/CreateInvoice/CreateInvoicePage';
import { selectPromoFreeActive } from '../reducers/cart';

export function Payment({
  vaultedCards,
  cardsLoading,
  cartItems,
  subTotal,
  taxAndTotal,
  orderLoading,
  eventDetails,
  deliveryAddress,
  guestDetails,
  destination,
  paymentMethod,
  eventSummaryValid,
  validateZip,
  zipLoading,
  cardSelected,
  submitOrder,
  submitOrderLoading,
  addressValidated,
  isDisabled,
  restaurantContactDetails,
  submitWarnings,
  isEditMode,
  hideNewPaymentOption,
  validateZipSuccess,
  subTotalAmountNoFormatted,
  addDeliveryTip,
  totalAmount,
  deliveryTip,
  deliveryTipLoading,
  selectedPercentage,
  selectedCustomTipValue,
  hidePayLater,
  deliveryTipIsDisplayed,
  cateringReason,
  secondaryContactDetails,
  promoFreeActive,
  allItemsPromoFree,
  specialInstructionMessage,
  paperGoodsRequired,
  showDefaultPaperGoodsMessage,
  taxAmount,
}) {
  const { selectedMethod } = paymentMethod;
  const totalAmountPrice = totalAmount !== 0 ? formatPrice(totalAmount) : taxAndTotal;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: /* istanbul ignore next reason: part of externall call */ () => componentRef.current,
  });

  return (
    <>
      <OrderInformation
        eventDetails={eventDetails}
        deliveryAddress={deliveryAddress}
        guestDetails={guestDetails}
        destination={destination}
        eventSummaryValid={eventSummaryValid}
        addressValidated={addressValidated}
        restaurantContactDetails={restaurantContactDetails}
        cartItems={cartItems}
        subTotal={subTotal}
        taxAndTotal={totalAmountPrice}
        orderLoading={orderLoading}
        deliveryTip={deliveryTip}
        deliveryTipIsDisplayed={deliveryTipIsDisplayed}
        deliveryTipLoading={deliveryTipLoading}
        cateringReason={cateringReason}
        secondaryContactDetails={secondaryContactDetails}
        promoFreeActive={promoFreeActive}
        specialInstructionMessage={specialInstructionMessage}
        isEditMode={isEditMode}
        paperGoodsRequired={paperGoodsRequired}
        showDefaultPaperGoodsMessage={showDefaultPaperGoodsMessage}
        taxAmount={taxAmount}
      />
      <Section>
        <SectionHeader>Payment</SectionHeader>
        <ConnectedPaymentOptions
          vaultedCards={vaultedCards}
          cardsLoading={cardsLoading}
          paymentMethod={paymentMethod}
          validateZip={validateZip}
          zipLoading={zipLoading}
          cardSelected={cardSelected}
          hideNewPaymentOption={hideNewPaymentOption}
          validateZipSuccess={validateZipSuccess}
          hidePayLater={hidePayLater}
          allItemsPromoFree={allItemsPromoFree}
        />
      </Section>
      {deliveryTipIsDisplayed && (
        <Section>
          <SectionHeader>Add Tip</SectionHeader>
          <DeliveryTips
            subTotal={subTotalAmountNoFormatted}
            addDeliveryTip={addDeliveryTip}
            selectedPercentage={selectedPercentage}
            selectedCustomTipValue={selectedCustomTipValue}
          />
        </Section>
      )}
      <Section>
        <>
          <div style={{ display: 'none' }}>
            <CreateInvoicePage
              ref={componentRef}
              eventDetails={eventDetails}
              deliveryAddress={deliveryAddress}
              guestDetails={guestDetails}
              destination={destination}
              eventSummaryValid={eventSummaryValid}
              addressValidated={addressValidated}
              restaurantContactDetails={restaurantContactDetails}
              cartItems={cartItems}
              subTotal={subTotal}
              taxAndTotal={totalAmountPrice}
              orderLoading={orderLoading}
              deliveryTip={deliveryTip}
              deliveryTipIsDisplayed={deliveryTipIsDisplayed}
              deliveryTipLoading={deliveryTipLoading}
              cateringReason={cateringReason}
              secondaryContactDetails={secondaryContactDetails}
              promoFreeActive={promoFreeActive}
              specialInstructionMessage={specialInstructionMessage}
              isEditMode={isEditMode}
              paperGoodsRequired={paperGoodsRequired}
              showDefaultPaperGoodsMessage={showDefaultPaperGoodsMessage}
              taxAmount={taxAmount}
            />
          </div>
          <CreateInvoiceButton onPrint={handlePrint}>Print Order Summary</CreateInvoiceButton>
        </>
      </Section>
      <Section>
        <SubmitOrderButton
          selectedMethod={selectedMethod}
          submitWarnings={submitWarnings}
          isDisabled={isDisabled}
          submitOrder={submitOrder}
          submitOrderLoading={submitOrderLoading}
          isEditMode={isEditMode}
        />
      </Section>
    </>
  );
}

Payment.propTypes = {
  vaultedCards: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ])),
  cardsLoading: PropTypes.bool,
  cartItems: PropTypes.arrayOf(PropTypes.any),
  subTotal: PropTypes.string,
  taxAndTotal: PropTypes.string,
  orderLoading: PropTypes.bool,
  submitOrder: PropTypes.func.isRequired,
  eventDetails: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  deliveryAddress: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  guestDetails: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  destination: PropTypes.string.isRequired,
  paymentMethod: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  eventSummaryValid: PropTypes.bool,
  validateZip: PropTypes.func.isRequired,
  zipLoading: PropTypes.bool,
  cardSelected: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  submitOrderLoading: PropTypes.bool,
  restaurantContactDetails: PropTypes.objectOf(PropTypes.string),
  addressValidated: PropTypes.bool,
  isDisabled: PropTypes.bool,
  submitWarnings: PropTypes.arrayOf(PropTypes.any),
  isEditMode: PropTypes.bool,
  hideNewPaymentOption: PropTypes.bool,
  validateZipSuccess: PropTypes.func,
  subTotalAmountNoFormatted: PropTypes.number,
  addDeliveryTip: PropTypes.func,
  totalAmount: PropTypes.number,
  deliveryTip: PropTypes.objectOf(PropTypes.number),
  deliveryTipLoading: PropTypes.bool,
  selectedPercentage: PropTypes.number,
  selectedCustomTipValue: PropTypes.number,
  // selectedLocation: PropTypes.string,
  hidePayLater: PropTypes.bool,
  deliveryTipIsDisplayed: PropTypes.bool,
  promoFreeActive: PropTypes.bool,
  cateringReason: PropTypes.string,
  secondaryContactDetails: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  allItemsPromoFree: PropTypes.bool,
  specialInstructionMessage: PropTypes.string,
  paperGoodsRequired: PropTypes.bool,
  showDefaultPaperGoodsMessage: PropTypes.bool,
  taxAmount: PropTypes.string,
};

Payment.defaultProps = {
  cardsLoading: false,
  vaultedCards: [],
  cartItems: [],
  subTotal: '',
  taxAndTotal: '',
  orderLoading: false,
  eventDetails: {},
  deliveryAddress: {},
  guestDetails: {},
  paymentMethod: {},
  eventSummaryValid: false,
  zipLoading: false,
  cardSelected: {},
  submitOrderLoading: false,
  restaurantContactDetails: {},
  addressValidated: false,
  isDisabled: false,
  submitWarnings: [],
  isEditMode: false,
  hideNewPaymentOption: false,
  validateZipSuccess: () => {},
  subTotalAmountNoFormatted: 0,
  addDeliveryTip: () => {},
  totalAmount: 0,
  deliveryTip: {},
  deliveryTipLoading: false,
  selectedPercentage: 0,
  selectedCustomTipValue: 0,
  // selectedLocation: '',
  hidePayLater: false,
  deliveryTipIsDisplayed: false,
  promoFreeActive: false,
  cateringReason: '',
  secondaryContactDetails: {},
  allItemsPromoFree: false,
  specialInstructionMessage: '',
  paperGoodsRequired: false,
  showDefaultPaperGoodsMessage: false,
  taxAmount: '',
};

function mapStateToProps(state) {
  return {
    vaultedCards: selectVaultedCards(state),
    cardsLoading: selectCardsLoading(state),
    cartItems: selectCartWithPrices(state),
    subTotal: selectFormattedSubTotalAmount(state),
    taxAndTotal: selectTaxAndTotal(state),
    orderLoading: selectOrderIsLoading(state),
    eventDetails: getFormValues('details')(state),
    deliveryAddress: selectDeliveryAddressForOrder(state),
    guestDetails: getFormValues('guest')(state),
    eventSummaryValid: selectEventSummaryValid(state),
    destination: selectDestination(state),
    paymentMethod: getFormValues('paymentMethod')(state),
    zipLoading: selectZipLoading(state),
    cardSelected: selectCardSelected(state),
    submitOrderLoading: selectSubmitOrderLoading(state),
    restaurantContactDetails: selectLocationContactDetails(state),
    addressValidated: selectDeliveryAddressValid(state),
    orderError: selectUpdateOrderError(state),
    isDisabled: selectSubmitButtonIsDisabled(state),
    formSyncErrors: selectFormSyncErrors(state),
    submitWarnings: selectAllSubmitWarnings(state),
    isEditMode: selectEditMode(state),
    hideNewPaymentOption: selectHideNewPaymentOption(state),
    subTotalAmountNoFormatted: selectSubTotalAmount(state),
    totalAmount: selectTotalAmount(state),
    deliveryTip: selectDeliveryTip(state),
    deliveryTipLoading: selectDeliveryTipLoading(state),
    selectedPercentage: selectSelectedPercentageTipValue(state),
    selectedCustomTipValue: selectSelectedCustomTipValue(state),
    selectedLocation: selectLocationNumber(state),
    hidePayLater: selectPayLaterNotAllowed(state),
    deliveryTipIsDisplayed: selectDeliveryTipIsDisplayed(state),
    cateringReason: selectCateringOccasion(state),
    secondaryContactDetails: getFormValues('secondaryContact')(state),
    promoFreeActive: selectPromoFreeActive(state),
    allItemsPromoFree: selectAllItemsPromoFree(state),
    specialInstructionMessage: selectSpecialInstructionMessage(state),
    paperGoodsRequired: selectPaperGoodsRequired(state),
    showDefaultPaperGoodsMessage: selectShowDefaultPaperGoodsMessage(state),
    taxAmount: selectFormattedTaxAmount(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...orderActions,
    ...guestActions,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
