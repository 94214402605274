import { Button, Section } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
// eslint-disable-next-line import/no-cycle
import {
  SlideModal,
  Disclaimer,
} from '../index';
import WebsitePreview from '../../assets/website_preview.png';
import constants from '../../constants';

const AddNewCard = ({ history, visible }) => {
  const back = (e) => {
    e.stopPropagation();
    history.goBack();
  };
  return (
    <SlideModal
      title={constants.REQUEST_PAYMENT}
      visible={visible}
      className="add-new-card"
      right={(
        <Button
          variant="transparent"
          secondary
          onClick={back}
        >
          Close
        </Button>
      )}
    >
      <StyledAddNewCard>
        <Disclaimer>
          Immediately after their order is placed, the guest will receive an email
          with a link to a website to view their order, and add a form of payment.
        </Disclaimer>
        <br />
        <ul className="reminders">
          <li>
            Remind the guest they will have four hours after receiving the
            email to enter their payment information.
            If the four hour window passes, they will need to call the Restaurant.
          </li>
          <li>
            If the guest is a Chick-fil-A One member, they will only have to add
            payment information once and can keep the card on file.
          </li>
          <li>
            The guest will have the ability to create a Chick-fil-A One
            account on the website.
          </li>
        </ul>
        <br />
        <Disclaimer className="preview-title">Website Preview</Disclaimer>
        <img className="preview" src={WebsitePreview} alt="Website Preview" />
        <br />
      </StyledAddNewCard>
    </SlideModal>
  );
};

const StyledAddNewCard = styled(Section)`
  margin: 15px;

  & .reminders {
    margin: 0 auto;
  }

  & .reminders li {
    font-size: 12px;
    margin: 10px;
  }

  & .preview {
    border: 1px solid lightgray;
    width: 80%;
    display: block;
    margin: 10px auto;
  }
  & .preview-title {
    text-align: center;
  }
`;

AddNewCard.propTypes = {
  visible: PropTypes.bool,
  history: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ])),
};

AddNewCard.defaultProps = {
  history: {},
  visible: false,
};

export default AddNewCard;
